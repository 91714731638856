
.tab {
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
}

.tabContent {
    padding: 12px 12px;
    box-sizing: border-box;
    margin-top: 8px;
    border-radius: 36px;
    background-color: #F2F2F2;
    height: 150px;
    display: flex;
    justify-content: center;

}

.tabContent img {
    display: inline-block;
    height: 100%;
    margin: 0 8px;
    box-sizing: border-box;
}
