
.selectContainer {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border: none;
    background-color: transparent;
}

.selectControl {
    -webkit-box-align: center;
    align-items: center;
    border-color: rgb(204, 204, 204);
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    /*position: relative;*/
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    outline: 0px !important;
    max-height: 100%;
    background-color: #00A99E;
    border-radius: 12px;
    overflow: hidden;
}

.selectedValueContainer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 0 0 0 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: white;
    height: calc(2.25rem + 2px);
    border-radius: 0 12px 12px 0;
}

.selectedValue {
    color: rgb(51, 51, 51);
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    /*box-sizing: border-box;*/
    /*height: 100%;*/
}

.indicators {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: transparent;
    min-width: 50px;

}

.indicator {
    color: rgb(204, 204, 204);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.menu {
    top: 100%;
    margin: 8px 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    display: block;
}

.option {
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    box-sizing: border-box;
}

.option:hover {
    background-color: rgba(0, 169, 158, 0.3);
}

.active {
    background-color: #00A99E;
    color: white;
}


.arrowDown {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

}

