/* Ensure proper sizing */
* {
    box-sizing: border-box;
}

/* Column container */
.row {
    display: flex;
    flex-wrap: wrap;
}

/* The side navigation menu */
.sidebar {
    padding: 0;
    width: 200px;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    margin: 48px 0 0;
}

/* Sidebar links */
.sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
    background-color: #4CAF50;
    color: white;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
main.content {
    /*margin-left: 200px;*/
    /*padding: 1px 16px;*/
    height: 100%;
    width: 100%;
    /*margin-top: 54px;*/
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar a {
        float: left;
    }

    main.content {
        margin-left: 0;
    }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}
