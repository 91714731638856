.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: block;
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.modalDialog {
    position: relative;
    width: 90%;
    /*width: auto;*/
    margin: 0.5rem auto 0.5rem auto;
    pointer-events: none;
}

.modalContent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modalHeader {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.modalTitle {
    margin-bottom: 0;
    line-height: 1.5;
}

.modalBody {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modalFooter {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    border: none;
}

.button {
    background-color: #2bbbad;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}


/* Add a hover effect for buttons */
.button:hover {
    opacity: 0.8;
}

.Success {
    color: #5C9210;
}


@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

@media (min-width: 768px) {
    .modalDialog {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .modalDialog {
        width: 60%;
    }
}
