
.tab {
    overflow: hidden;
    /*border-bottom: 1px solid #ccc;*/
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 12px;
}

/*
page: #E6E7E8
tab content #F2F2F2
disabed: #E6E6E6
maincolor #00A99E
*/
/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: #F2F2F2;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px 24px;
    transition: 0.3s;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 700;
}

/* Change background color of buttons on hover */
/*.tab button:hover {*/
/*    background-color: white;*/
/*}*/

/* Create an active/current tablink class */
.tab button.active {
    background-color: #00A99E;
    /*border-bottom: 2px solid #2bbbad;*/
    /*border-left: 1px solid #ccc;*/
    /*border: 1px ;*/
    color: whitesmoke;
}
