body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #E6E7E8;
    font-family: sans-serif;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

input[type=text], input[type=password], select, textarea {
    width: 100%;
    /*padding: 12px;*/
    border: 1px solid #ccc;
    /*border-radius: 4px;*/
    box-sizing: border-box;
    resize: vertical;
    padding: 12px 20px;
    height: calc(2.25rem + 2px);

}


@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

/* Set a style for all buttons */
.button {
    background-color: #00A99E;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}


/* Add a hover effect for buttons */
.button:hover {
    opacity: 0.8;
}

/* Extra style for the cancel button (red) */
.cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
}

.validatebtn {
    width: auto;
    padding: 10px 18px;
    margin-right: 3px;
    background-color: #00A99E;
}

.notvisiblebtn {
    display: none;
}


table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;

}

/* tr:nth-child(even) {background-color: #f2f2f2;} */
tr:hover {
    background-color: #f5f5f5;
}


.Button {
    background-color: #dddddd;
    border: none;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    width: 94%;
    font-weight: bold;
    border-radius: 5px;
}

/* .Button:first-of-type {
  margin-left: 0;
  padding-left: 0;
} */

.Success {
    color: #00A99E;
}

input[type=checkbox]:checked input[type=checkbox]::before {
    background-color: #00A99E;
    color: white;
}


/*test*/


.input {
    display: block;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input::-ms-expand {
    background-color: transparent;
    border: 0;
}

.input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #00A99E;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(57, 255, 236, 0.25);
}

.input::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::placeholder {
    color: #6c757d;
    opacity: 1;
}

.input:disabled, .input[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.input:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

select.input:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}
