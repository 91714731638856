
.tabContent {
    display: none;
    padding: 8px 24px;
    border: 1px solid #ccc;
    /*border-top: none;*/
    border-radius: 36px;
    box-sizing: border-box;
    margin-top: 8px;
    background-color: #F2F2F2;
    box-shadow: 2px 2px #939393;

}

/* Floating column for labels: 25% width */
/*.col25 {*/
/*    float: left;*/
/*    width: 25%;*/
/*    !*margin-top: 12px;*!*/
/*}*/

/* Floating column for inputs: 75% width */
.col100 {
    float: left;
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    background-color: #00A99E;
    border-radius: 12px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}


/* Style the label to display next to the inputs */
label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}

/* Style inputs, select elements and textareas */
input[type=text], input[type=number], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    /*border-radius: 4px;*/
    box-sizing: border-box;
    resize: vertical;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Style the submit button */
input[type=submit] {
    background-color: #2bbbad;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    float: right;
}


