.label {
    display: inline-block;
    /*margin-bottom: .5rem;*/
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem);
    /*font-size: 0.875rem;*/
    line-height: 1.5;
    margin-left: 0.7rem;
    font-size: 16px;
    font-weight: 400;
}

@media (min-width: 992px) {
    .label {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }
}

@media (min-width: 1200px) {
    .label {
        padding-top: calc(0.5rem + 1px);
        padding-bottom: calc(0.5rem + 1px);
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.input {
    display: block;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 12px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    padding-right: 0;
    padding-left: 0;
    /*font-size: 16px;*/
    /*font-weight: 400;*/
    height: calc(2.25rem + 2px);

}


.input::-ms-expand {
    background-color: transparent;
    border: 0;
}

.input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #00A99E;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(57, 255, 236, 0.25);
}

.input::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.input::placeholder {
    color: #6c757d;
    opacity: 1;
}

.input:disabled, .input[readonly] {
    background-color: #E6E6E6;
    opacity: 1;
}

select.input:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

select.input:focus::-ms-value {
    color: #495057;
    background-color: #E6E6E6;
}

select {
    background-color: #E6E6E6;
    border-radius: 12px;
    color: #00A99E;

    display: block;
    font-size: 16px;
    /*font-family: sans-serif;*/
    font-weight: 700;
    /*color: #444;*/
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    /*border: 1px solid #aaa;*/
    /*box-shadow: 0 1px 0 1px rgba(0,0,0,.04);*/
    /*border-radius: .5em;*/
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /*background-color: #fff;*/
}

.arrowDown {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

}

.suffix {
    padding: 10px;
    min-width: 50px;
    text-align: center;
    /*border: 1px solid #ccc;*/
    border-left: none;

    color: white;
    background-color: transparent;
    /*height: 100%;*/
    vertical-align: middle;
    font-size: 0.875rem;
    /*line-height: 1.5;*/
    /*line-height: normal;*/
}
